
import {defineComponent,  computed} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "HelpEmbed",
  props: {
    title: {type: String, required: false, default:'Learn More'},
    // helpId: {required: false, default: 'oqove08cZZ'},
    helpId: {required: false, default: '0L53QGBuW'},
    bold: {type: Boolean, default: true},
    button: {type: Boolean, default: false},
    clazz: {type:String} // overrides other options
  },
  setup(props) {
    const mappedClass = computed(() => {
      if (props.clazz) {
        return props.clazz;
      }

      let baseClass = props.button ?
          'btn btn-primary er fs-6 px-8 py-4' :
          'link-primary cursor-pointer hover py-5';

      if (props.bold) {
        baseClass += " fw-bold";
      }

      return baseClass;
    });

    return {
      ...LoadPanel(),
    mappedClass
    }
  }
})
